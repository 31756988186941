import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EBFAF8",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.greyish.6",
    padding: "15px 20px",
    alignItems: "center",
    justifyContent: "center",
  },
  formWrapper: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: "2%",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  selectWrapper: {
    minWidth: "100px",
    borderTopRightRadius: "0px",
    borderColor: "unset",
  },

  label: {
    fontSize: "14px",
    mt: "15px",
  },

  heading: {
    fontSize: "20px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "black",
    marginBottom: "20px",
  },
  renderButtonText2: {
    display: "block",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "600",
    color: "#36B1A0",
    textDecoration: "none",
  },
});

export default styles;
