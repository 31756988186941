import { Box, Grid } from "@mui/material";
import { Button, Input } from "../../../common/FormComponents";

import { getStyles } from "src/styles/theme";
import { useForm } from "react-hook-form";
import errorMessage from "src/constants/errorMessage";
import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { updateSeedlingRowDetailsData } from "src/utils/api/dashboard";
import { useEffect } from "react";
import defaultStyles, { StylesClasses } from "./styles";
import { useSearchParams } from "react-router-dom";

export function EditSeedlingForm({ rowData, selfState, refetch }: any) {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const [searchParams] = useSearchParams();

  const landOwnershipEpid = searchParams.get("id");

  const { mutate, isLoading } = useMutation(
    "postUpdatedSeedlingData",
    ({ updateValue }: { updateValue: any }) =>
      updateSeedlingRowDetailsData(rowData.id, updateValue),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log("data updated successfully");
        selfState(false);
        refetch();
      },
      onError: (error: any) => {
        console.log("error occured while updating");
      },
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      commonName: rowData?.commonName,
      botanicalName: rowData?.botanicalName,
      numberOfSeedlings: rowData?.numberOfSeedlings,
    },
  });

  useEffect(() => {
    reset({
      commonName: rowData?.data?.commonName,
      botanicalName: rowData?.data?.botanicalName,
      numberOfSeedlings: rowData?.data?.numberOfSeedlings,
    });
  }, [rowData]);

  const onSubmit = (data: any) => {
    mutate({
      updateValue: {
        landOwnershipEpid: landOwnershipEpid,
        commonName: data.commonName,
        botanicalName: data.botanicalName,
        numberOfSeedlings: parseInt(data.numberOfSeedlings, 10),
      },
    });
  };

  return (
    <Box {...styles("wrapper")}>
      <Box
        {...styles("formWrapper")}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="commonName"
              rules={{
                required: errorMessage.required,
                maxLength: {
                  value: 500,
                  message: "More then 500 characters are not allowed",
                },
              }}
              control={control}
              errors={errors}
              placeholder="Enter Common Name"
              label="Common Name"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              rules={{ required: errorMessage.required }}
              name="botanicalName"
              control={control}
              placeholder="Enter Botanical Name"
              label="Botanical Name"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              customStyles={{
                label: defaultStyles.label,
                wrapper: defaultStyles.selectWrapper,
                input: {
                  borderColor: "#36B1A0;",
                  boxShadow: "unset",
                },
              }}
              name="numberOfSeedlings"
              rules={{ required: errorMessage.required }}
              control={control}
              errors={errors}
              placeholder="Enter Number of Seedling"
              label="Number of Seedling"
              type="number"
            />
          </Grid>
        </Grid>
        <Box>
          <Button text="update" type="submit" loading={isLoading} />
        </Box>
      </Box>
    </Box>
  );
}
